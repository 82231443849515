const COLORS = {
    light_gray: "#969696",
    light_viewline: "#eeeeee",
    black: "#000000",
    view_line_color: "#d9d9d9",
    place_hlder: "#828282",
    white: "#ffffff",
    lightWhite: "#FAFAFC",
    app_theme_color: "#f066a4", 
    app_theme_color2: "#5b74b7",
    search_text: "868686"
};
const FONT = {
    Regular: "Regular",
    Medium: "Medium",
    Bold: "Bold",
    SemiBold: "SemiBold"
};





export { COLORS, FONT };
