import "./A1Mart_Home.css";
import { useTranslation } from "react-i18next";
import logo from "../../assets/Img/logo.png";
import flag from "../../assets/Img/malawiflag.png";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import search from "../../assets/Img/search_gray.png";
import product from "../../assets/Img/product.jpg";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { COLORS } from "../ColorsFile/themes.js";
import {
    List,
    ListItem,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import MeiliSearch from "meilisearch";

const A1MartHome = () => {

    const { t } = useTranslation();
    const [searchvalue, setsearchvalue] = useState('');
    const [data, setdatas] = useState([]);

    const data1 = [
        { id: 1, price: '2000', product_name: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'Madurai', quantity: '5' },
        { id: 2, price: '1500', product_name: 'AASHIRVAAD Wheat Flour - Superior MP Test Product Name Test Test Product Name A1 Mart', image: flag, location: 'TamilNadu', quantity: '3' },
        { id: 3, price: '2000', product_name: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'Madurai', quantity: '5' },
        { id: 4, price: '1500', productname: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'TamilNadu TamilNadu', quantity: '3' },
        { id: 5, date: '2000', productname: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'Madurai', quantity: '5' },
        { id: 6, date: '1500', productname: 'AASHIRVAAD Wheat Flour - Superior MP Test Product Name Test Test Product Name A1 Mart', image: flag, location: 'TamilNadu', quantity: '3' },
        { id: 7, date: '2000', productname: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'Madurai', quantity: '5' },
        { id: 8, date: '1500', productname: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'TamilNadu', quantity: '3' },
    ];

    const data2 = [
        { id: 1, date: '2000', productname: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'Madurai', quantity: '5' },
        { id: 2, date: '1500', productname: 'AASHIRVAAD Wheat Flour - Superior MP Test Product Name Test Test Product Name A1 Mart', image: flag, location: 'TamilNadu', quantity: '3' },
        { id: 3, date: '2000', productname: 'Test Product Name Test Product Name A1 Mart Test', image: flag, location: 'Madurai', quantity: '5' },
    ];

    const [stockListVisibility, setstockListVisibility] = useState({});

    const apicall = async (val) => {
        const client = new MeiliSearch({
            host: 'https://edge.meilisearch.com',
            apiKey: 'e79e9e0be384c5ab99dbc5652cf8c2004991f86360ae06481a3311a395cae3e3',
        })
        const index = client.index('iar-a1mart-stock');
        const datas = await index.search(val);
        console.log(datas.hits)
        setdatas(datas.hits)
    }

    useEffect(() => {
        apicall(searchvalue);
    }, [searchvalue])

    const HandleSearch = (e) => {
        const searchValue = e.target.value;
        console.log(searchValue)
        setsearchvalue(searchValue);
    }

    const handleClick = (itemId) => {

        setstockListVisibility(prevVisibility => ({
            ...prevVisibility,
            [itemId]: !prevVisibility[itemId]
        }));
    };

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
        // Additional actions you want to perform on mouse enter
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        // Additional actions you want to perform on mouse leave
    };

    Object.keys(COLORS).forEach(key => {
        document.documentElement.style.setProperty(`--${key}`, COLORS[key]);
    });
    return (
        <div className="MainContainer">
            <div className="headerline" />
            <Grid container style={{ paddingTop: 25, paddingBottom: 25, }}>
                <Grid item xs={6} md={6} style={{ justifyContent: 'flex-start', alignItems: 'center', display: 'flex', flexDirection: 'row', }}>
                    <img src={logo} alt="logo"  className="logoimg" />
                    <div className="app_name">{t("A1 Mart")}</div>
                </Grid>
                <Grid item xs={6} md={6} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
                    <div style={{ backgroundColor: COLORS.white, display: 'flex', borderRadius: 6,  boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.2)', alignItems: 'center', padding: 7, }} className="searchBackground" >
                        <img src={search} alt="search" style={{ width: '17px', height: '17px', marginLeft: '5px' }} />
                        <TextField
                            placeholder={t("search_your_products")}
                            style={{ flex: 1, display: 'flex', marginLeft: '8px', marginRight: '5px' }}
                            InputProps={{
                                disableUnderline: true, style: {
                                    fontSize: '13px', color: "black", top: 1
                                }
                            }}
                            onChangeCapture={HandleSearch}
                            variant="standard" />
                    </div>
                </Grid>
            </Grid>
            <div className="viewline" />
            <div className="listspace">
                <Grid container>
                    {data.map(item => (
                        <Grid item md={6} xs={12} sm={12} lg={6} key={item.id} onClick={() => handleClick(item.product_id)}>
                            <div style={{ flexDirection: 'column', display: 'flex', marginBottom: '10px', }} className="gridspace">
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', flex: 1, }}>
                                    <div  style={{ flexDirection: 'column', display: 'flex', flex: 0.8, }}>
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', flexDirection:'row' }}>
                                            <div className="ProductName">{item.product_name != null && item.product_name !== undefined && item.product_name !== "" ? item.product_name : ""}</div>
                                            <div className="ProductId">{item.product_id != null && item.product_id !== undefined && item.product_id !== "" ? ( "( "+item.product_id +" )"): ""}</div>
                                        </div>
                                        {(
                                            <Grid container style={{ marginTop: 8, marginBottom: 2 }}>
                                                {item.stock_info.map(item => (
                                                    <Grid item sm={12} style={{ marginRight: 2, flexDirection:'column' }}>
                                                        <div style={{ flexDirection: 'row', display: 'flex', flex: 1, marginBottom: 6 }}>
                                                            <div className="Location" > {item.location != null && item.location !== undefined && item.location !== "" ? (item.location + ": ") : ""}</div>
                                                            <div className="Location" > {item.stock != null && item.stock !== undefined && item.stock !== "" ? item.stock : ""}</div>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                                            <div style={{ color: COLORS.app_theme_color2, fontSize: '13px', fontWeight: '600', }}>{item.price != null && item.price !== undefined && item.price !== "" ? ("₹" + item.price) : ""}</div>
                                        </div>
                                    </div>
                                    <div style={{ alignItems: 'center', justifyContent: 'flex-end', display: 'flex', flexDirection: 'row', flex: 0.2, }}>
                                        <img src={item.image} style={{ width: '60px', height: '60px', }} />
                                    </div>
                                </div>
                                <div style={{ flex: 1, display: 'flex', borderBottom: '2px solid  #f3f6fa', marginTop: '10px' }}></div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default A1MartHome;
