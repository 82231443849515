import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import AppContext from "./utils/context";
import A1MartHome from "./components/A1Mark_Home/A1Mart_Home";

function App() {
  const [apiToken, setApiToken] = useState('');
  const [userDetail, setUserDetail] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const setToken = (token) => {
    localStorage.setItem('token', JSON.stringify(token));
    
  }
  const getToken = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    setApiToken(token)
  }

  const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  }
  const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserDetail(user)
  }

  useEffect(() => {
    getToken();
    getUser();
  }, []);

  return (
    <HashRouter >
      <AppContext>
          <Routes>
            <Route index element={<A1MartHome />}></Route>
          </Routes>
      </AppContext>
    </HashRouter >
  );
}

export default App;